import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from "vue";
import Shift from "@/typesold/shift";
import Able from "@/typesold/able";
import Shift_memo from "@/typesold/shift_memo";

export type Employee = {
  id: number;
  name: string;
};
export type EmployeeHash = {
  [id: number]: string;
};
export type Data = {
  employees: any;
  employee_hash: any;
  is_valid?: boolean;
  is_set: boolean;
  fetching?: Promise<void>;
};
export type BranchlyData = {
  [branch_id: number]: Data;
};
export type YearlyData = {
  [year: number]: BranchlyData;
};

@Module({ dynamic: true, store, name: "document_employee_restax", namespaced: true })
class Monthly extends VuexModule {
  data: YearlyData = {};

  @Mutation
  public set({ branch_id, year, data }: { branch_id: number; year: number; data: Data }) {
    var employee_hash = util.createHashObject(data.employees, "id");
    Vue.set(this.data[year], branch_id, {
      ...data,
      employee_hash,
      is_valid: true,
      is_set: true,
      fetching: null,
    });
  }

  @Mutation
  public disable({ branch_id, year }: { branch_id: number; year: number }) {
    if (this.data[year] && this.data[year][branch_id]) {
      this.data[branch_id][year].is_valid = false;
    }
  }
  @Mutation
  public disable_all() {
    Object.keys(this.data).forEach((year) => {
      Object.keys(this.data[year]).forEach((branch_id) => {
        this.data[year][branch_id].is_valid = false;
      });
    });
  }
  @Mutation
  public setFetching({
    branch_id,
    year,
    value,
  }: {
    branch_id: number;
    year: number;
    value: Promise<void>;
  }) {
    this.data[year][branch_id].fetching = value;
  }

  // actions
  @Action({ rawError: true })
  public async fetch({ branch_id, year }: { branch_id: number; year: number }) {
    //ないなら作る
    if (!this.data[year]) {
      Vue.set(this.data, year, {});
    }
    Vue.set(this.data[year], branch_id, {
      employees: [],
      employee_hash: {},
      is_valid: false,
      is_set: false,
      fetching: null,
    });

    if (this.data[year][branch_id].fetching) {
      await this.data[year][branch_id].fetching;
    } else {
      const promise = util
        .post("document/read_employee_restax", { branch_id, year })
        .then((res) => {
          this.set({ branch_id, year, data: res.data });
        });
      this.setFetching({ branch_id, year, value: promise });
      await promise;
    }
  }
}

export default getModule(Monthly);
