import { Component, Vue, Mixins } from "vue-property-decorator";
import global from "@/vuex/document/global";
import branch_monthly from "@/vuex/document/branch_monthly";
import employee_retired from "@/vuex/document/employee_retired";
import employee_monthly from "@/vuex/document/employee_monthly";
import branch_yearly from "@/vuex/document/branch_yearly";
import employee_yearly from "@/vuex/document/employee_yearly";
import employee_emp_ins_get from "@/vuex/document/employee_emp_ins_get";
import employee_dependant from "@/vuex/document/employee_dependant";
import employee_bonus_pay from "@/vuex/document/employee_bonus_pay";
import employee_restax from "@/vuex/document/employee_restax";
import history from "@/vuex/document/history";
import waiting from "@/vuex/document/waiting";
import history_employeely from "@/vuex/document/history_employeely";
import contract_document from "@/vuex/document/contract_document";
import siku_yearly from "@/vuex/document/siku_yearly";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/document/manager";

@Component
export default class mixinDocument extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_set_branch_monthly(): boolean {
    return (
      branch_monthly.data[this.m.selected_yearmonth] &&
      branch_monthly.data[this.m.selected_yearmonth][this.monthly_branch_type] &&
      branch_monthly.data[this.m.selected_yearmonth][this.monthly_branch_type].is_set
    );
  }
  get is_set_branch_yearly(): boolean {
    return (
      branch_yearly.data[this.m.selected_year] &&
      branch_yearly.data[this.m.selected_year][this.yearly_branch_type] &&
      branch_yearly.data[this.m.selected_year][this.yearly_branch_type].is_set
    );
  }
  get is_set_employee_retired(): boolean {
    return (
      employee_retired.data[this.viewing_branch] &&
      employee_retired.data[this.viewing_branch].is_set
    );
  }
  get is_set_employee_monthly(): boolean {
    return (
      employee_monthly.data[this.m.selected_yearmonth] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch][
        this.monthly_branch_type
      ] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch][
        this.monthly_branch_type
      ].is_set
    );
  }
  get is_set_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.m.selected_year] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_set
    );
  }
  get is_set_employee_emp_ins_get(): boolean {
    return (
      employee_emp_ins_get.data[this.m.selected_year] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch][
        this.yearly_branch_type
      ] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_set
    );
  }
  get is_set_employee_dependant(): boolean {
    return (
      employee_dependant.data[this.m.selected_year] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_set
    );
  }
  get is_set_employee_bonus_pay(): boolean {
    return (
      employee_bonus_pay.data[this.m.selected_yearmonth] &&
      employee_bonus_pay.data[this.m.selected_yearmonth][this.viewing_branch] &&
      employee_bonus_pay.data[this.m.selected_yearmonth][this.viewing_branch].is_set
    );
  }
  get is_set_employee_restax(): boolean {
    return (
      employee_restax.data[this.m.selected_year] &&
      employee_restax.data[this.m.selected_year][this.viewing_branch] &&
      employee_restax.data[this.m.selected_year][this.viewing_branch].is_set
    );
  }
  get is_set_siku_yearly(): boolean {
    return siku_yearly.data[this.m.selected_year] && siku_yearly.data[this.m.selected_year].is_set;
  }
  get is_set_history(): boolean {
    if (this.m.selected_document && this.m.selected_document > 100) {
      return (
        history.data[this.m.selected_document - 100] &&
        history.data[this.m.selected_document - 100][this.viewing_branch] &&
        history.data[this.m.selected_document - 100][this.viewing_branch].is_set
      );
    } else {
      return false;
    }
  }
  get is_set_history_employeely(): boolean {
    if (this.m.selected_document && this.m.selected_document > 100) {
      return (
        history_employeely.data[this.m.selected_document - 100] &&
        history_employeely.data[this.m.selected_document - 100].is_set
      );
    } else {
      return false;
    }
  }
  get is_set_contract_document(): boolean {
    return (
      contract_document.data[this.contract_document_id] &&
      contract_document.data[this.contract_document_id].is_set
    );
  }
  get is_set_waiting(): boolean {
    return waiting.is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }
  get is_valid_branch_monthly(): boolean {
    return (
      branch_monthly.data[this.m.selected_yearmonth] &&
      branch_monthly.data[this.m.selected_yearmonth][this.monthly_branch_type] &&
      branch_monthly.data[this.m.selected_yearmonth][this.monthly_branch_type].is_valid
    );
  }
  get is_valid_branch_yearly(): boolean {
    return (
      branch_yearly.data[this.m.selected_year] &&
      branch_yearly.data[this.m.selected_year][this.yearly_branch_type] &&
      branch_yearly.data[this.m.selected_year][this.yearly_branch_type].is_valid
    );
  }
  get is_valid_employee_retired(): boolean {
    return (
      employee_retired.data[this.viewing_branch] &&
      employee_retired.data[this.viewing_branch].is_valid
    );
  }
  get is_valid_employee_monthly(): boolean {
    return (
      employee_monthly.data[this.m.selected_yearmonth] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch][
        this.monthly_branch_type
      ] &&
      employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch][
        this.monthly_branch_type
      ].is_valid
    );
  }
  get is_valid_employee_yearly(): boolean {
    return (
      employee_yearly.data[this.m.selected_year] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type] &&
      employee_yearly.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_valid
    );
  }
  get is_valid_employee_emp_ins_get(): boolean {
    return (
      employee_emp_ins_get.data[this.m.selected_year] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch][
        this.yearly_branch_type
      ] &&
      employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_valid
    );
  }
  get is_valid_employee_dependant(): boolean {
    return (
      employee_dependant.data[this.m.selected_year] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type] &&
      employee_dependant.data[this.m.selected_year][this.viewing_branch][this.yearly_branch_type]
        .is_valid
    );
  }
  get is_valid_employee_bonus_pay(): boolean {
    return (
      employee_bonus_pay.data[this.m.selected_yearmonth] &&
      employee_bonus_pay.data[this.m.selected_yearmonth][this.viewing_branch] &&
      employee_bonus_pay.data[this.m.selected_yearmonth][this.viewing_branch].is_valid
    );
  }
  get is_valid_employee_restax(): boolean {
    return (
      employee_restax.data[this.m.selected_year] &&
      employee_restax.data[this.m.selected_year][this.viewing_branch] &&
      employee_restax.data[this.m.selected_year][this.viewing_branch].is_valid
    );
  }
  get is_valid_siku_yearly(): boolean {
    return (
      siku_yearly.data[this.m.selected_year] && siku_yearly.data[this.m.selected_year].is_valid
    );
  }
  get is_valid_history(): boolean {
    if (this.m.selected_document && this.m.selected_document > 100) {
      return (
        history.data[this.m.selected_document - 100] &&
        history.data[this.m.selected_document - 100][this.viewing_branch] &&
        history.data[this.m.selected_document - 100][this.viewing_branch].is_valid
      );
    } else {
      return false;
    }
  }
  get is_valid_history_employeely(): boolean {
    if (this.m.selected_document && this.m.selected_document > 100) {
      return (
        history_employeely.data[this.m.selected_document - 100] &&
        history_employeely.data[this.m.selected_document - 100].is_valid
      );
    } else {
      return false;
    }
  }
  get is_valid_contract_document(): boolean {
    return (
      contract_document.data[this.contract_document_id] &&
      contract_document.data[this.contract_document_id].is_valid
    );
  }
  get is_valid_waiting(): boolean {
    return waiting.is_valid;
  }

  async get_global() {
    await global.fetch();
  }
  async get_branch_monthly() {
    if (this.m.selected_yearmonth !== null && this.monthly_branch_type !== undefined) {
      await branch_monthly.fetch({
        auth: this.s_auth,
        yearmonth: this.m.selected_yearmonth,
        type: this.monthly_branch_type,
      });
    }
  }
  async get_branch_yearly() {
    if (
      (this.m.selected_document == 5 ||
        this.m.selected_document > 100 ||
        this.yearly_branch_type == 0 ||
        this.m.selected_year !== null) &&
      this.yearly_branch_type !== undefined
    ) {
      await branch_yearly.fetch({
        auth: this.s_auth,
        year: this.m.selected_year,
        type: this.yearly_branch_type,
      });
    }
  }
  async get_employee_retired() {
    if (this.viewing_branch !== null) {
      await employee_retired.fetch({ branch_id: this.viewing_branch });
    }
  }
  async get_employee_monthly() {
    if (
      this.m.selected_yearmonth !== null &&
      this.viewing_branch !== null &&
      this.monthly_branch_type !== undefined
    ) {
      await employee_monthly.fetch({
        yearmonth: this.m.selected_yearmonth,
        branch_id: this.viewing_branch,
        type: this.monthly_branch_type,
      });
    }
  }
  async get_employee_yearly() {
    if (
      (this.yearly_branch_type == 0 ||
        this.m.selected_year !== null ||
        this.m.selected_document > 100) &&
      this.viewing_branch !== null &&
      this.yearly_branch_type !== undefined
    ) {
      await employee_yearly.fetch({
        year: this.m.selected_year,
        branch_id: this.viewing_branch,
        type: this.yearly_branch_type,
      });
    }
  }
  async get_employee_emp_ins_get() {
    if (this.viewing_branch !== null) {
      await employee_emp_ins_get.fetch({
        year: this.m.selected_year,
        branch_id: this.viewing_branch,
        type: this.yearly_branch_type,
      });
    }
  }
  async get_employee_dependant() {
    if (
      (this.yearly_branch_type == 0 ||
        this.m.selected_year !== null ||
        this.m.selected_document > 100) &&
      this.viewing_branch !== null &&
      this.yearly_branch_type !== undefined
    ) {
      await employee_dependant.fetch({
        year: this.m.selected_year,
        branch_id: this.viewing_branch,
        type: this.yearly_branch_type,
      });
    }
  }
  async get_employee_bonus_pay() {
    if (this.m.selected_yearmonth !== null) {
      await employee_bonus_pay.fetch({
        branch_id: this.viewing_branch,
        yearmonth: this.m.selected_yearmonth,
      });
    }
  }
  async get_employee_restax() {
    if (this.m.selected_year !== null) {
      await employee_restax.fetch({ branch_id: this.viewing_branch, year: this.m.selected_year });
    }
  }
  async get_siku_yearly() {
    if (this.m.selected_year !== null) {
      await siku_yearly.fetch({ year: this.m.selected_year });
    }
  }
  async get_history() {
    if (
      this.m.selected_document &&
      this.m.selected_document > 100 &&
      this.viewing_branch !== null
    ) {
      await history.fetch({
        document_template_id: this.m.selected_document - 100,
        branch_id: this.viewing_branch,
      });
    }
  }
  async get_history_employeely() {
    if (this.m.selected_document && this.m.selected_document > 100) {
      await history_employeely.fetch({ document_template_id: this.m.selected_document - 100 });
    }
  }
  async get_contract_document() {
    if (this.contract_document_id !== undefined) {
      await contract_document.fetch({ contract_document_id: this.contract_document_id });
    }
  }
  async get_waiting() {
    await waiting.fetch();
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data;
  }
  get branch_monthly() {
    if (!this.is_set_branch_monthly || !this.is_valid_branch_monthly) this.get_branch_monthly();
    if (this.is_set_branch_monthly)
      return branch_monthly.data[this.m.selected_yearmonth][this.monthly_branch_type];
  }
  get branch_yearly() {
    if (!this.is_set_branch_yearly || !this.is_valid_branch_yearly) this.get_branch_yearly();
    if (this.is_set_branch_yearly)
      return branch_yearly.data[this.m.selected_year][this.yearly_branch_type];
  }
  get employee_retired() {
    if (!this.is_set_employee_retired || !this.is_valid_employee_retired)
      this.get_employee_retired();
    if (this.is_set_employee_retired) return employee_retired.data[this.viewing_branch];
  }
  get employee_restax() {
    if (!this.is_set_employee_restax || !this.is_valid_employee_restax) this.get_employee_restax();
    if (this.is_set_employee_restax)
      return employee_restax.data[this.m.selected_year][this.viewing_branch];
  }
  get employee_monthly() {
    if (!this.is_set_employee_monthly || !this.is_valid_employee_monthly)
      this.get_employee_monthly();
    if (this.is_set_employee_monthly)
      return employee_monthly.data[this.m.selected_yearmonth][this.viewing_branch][
        this.monthly_branch_type
      ];
  }
  get employee_yearly() {
    if (!this.is_set_employee_yearly || !this.is_valid_employee_yearly) this.get_employee_yearly();
    if (this.is_set_employee_yearly)
      return employee_yearly.data[this.m.selected_year][this.viewing_branch][
        this.yearly_branch_type
      ];
  }
  get employee_emp_ins_get() {
    if (!this.is_set_employee_emp_ins_get || !this.is_valid_employee_emp_ins_get)
      this.get_employee_emp_ins_get();
    if (this.is_set_employee_emp_ins_get)
      return employee_emp_ins_get.data[this.m.selected_year][this.viewing_branch][
        this.yearly_branch_type
      ];
  }
  get employee_dependant() {
    if (!this.is_set_employee_dependant || !this.is_valid_employee_dependant)
      this.get_employee_dependant();
    if (this.is_set_employee_dependant)
      return employee_dependant.data[this.m.selected_year][this.viewing_branch][
        this.yearly_branch_type
      ];
  }
  get employee_bonus_pay() {
    if (!this.is_set_employee_bonus_pay || !this.is_valid_employee_bonus_pay)
      this.get_employee_bonus_pay();
    if (this.is_set_employee_bonus_pay)
      return employee_bonus_pay.data[this.m.selected_yearmonth][this.viewing_branch];
  }
  get siku_yearly() {
    if (!this.is_set_siku_yearly || !this.is_valid_siku_yearly) this.get_siku_yearly();
    if (this.is_set_siku_yearly) return siku_yearly.data[this.m.selected_year];
  }
  get history() {
    if (!this.is_set_history || !this.is_valid_history) this.get_history();
    if (this.is_set_history)
      return history.data[this.m.selected_document - 100][this.viewing_branch];
  }
  get history_employeely() {
    if (!this.is_set_history_employeely || !this.is_valid_history_employeely)
      this.get_history_employeely();
    if (this.is_set_history_employeely)
      return history_employeely.data[this.m.selected_document - 100];
  }
  get contract_document() {
    if (!this.is_set_contract_document || !this.is_valid_contract_document)
      this.get_contract_document();
    if (this.is_set_contract_document) return contract_document.data[this.contract_document_id];
  }
  get waiting() {
    if (!this.is_set_waiting || !this.is_valid_waiting) this.get_waiting();
    if (this.is_set_waiting) return waiting.data;
  }

  get each_contract_document() {
    return this.contract_document;
  }
  get contract_document_id() {
    return this.query.contract_document_id;
  }

  get branch_hash() {
    if (this.is_branch_monthly) {
      return this.branch_monthly.branch_hash;
    } else if (this.is_branch_yearly) {
      return this.branch_yearly.branch_hash;
    }
  }
  get document_templates() {
    return this.global.document_templates;
  }
  get document_template_hash() {
    return this.global.document_template_hash;
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch_document;
    if (branch_id === undefined) {
      if (this.s_auth == 2) branch_id = 0;
      else branch_id = Number(util.getLocalStorage("branch_id"));
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = 0;
    }
    this.query = { viewing_branch_document: branch_id };
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    this.query = { viewing_branch_document: val };
    this.m.resetSelectedEmployee();
  }

  get invisibleSpan() {
    if (!this.is_date && !this.is_yearmonth && !this.is_span && !this.is_year) return false;
    else if (this.header.plan == 2) return false;
    else {
      const today = new Date(util.formatDate(new Date(), "yyyy-mm-01"));
      let date = new Date();
      if (this.is_date) {
        if (this.m.selected_date === null) return false;
        date = new Date(util.formatDate(new Date(this.m.selected_date), "yyyy-mm-01"));
      } else if (this.is_yearmonth) {
        if (this.m.selected_yearmonth === null) return false;
        date = new Date(util.formatDate(new Date(`${this.m.selected_yearmonth}-01`), "yyyy-mm-01"));
      } else if (this.is_span) {
        if (this.m.selected_start_yearmonth === null || this.m.selected_end_yearmonth === null)
          return false;
        date = new Date(
          util.formatDate(new Date(`${this.m.selected_start_yearmonth}-01`), "yyyy-mm-01"),
        );
      } else if (this.is_year) {
        if (this.m.selected_year === null) return false;
        date = new Date(util.formatDate(new Date(`${this.m.selected_year}-01-01`), "yyyy-01-01"));
      }
      date.setFullYear(date.getFullYear() + 3);
      return date.getTime() <= today.getTime();
    }
  }

  get is_date() {
    return false;
  }
  get is_yearmonth() {
    return (
      this.m.selected_document == 0 ||
      this.m.selected_document == 1 ||
      this.m.selected_document == 2 ||
      this.m.selected_document == 16 ||
      this.m.selected_document == 24
    );
  }
  get is_span() {
    return this.m.selected_document == 6 || this.m.selected_document == 25;
  }
  get is_span_error() {
    if (this.m.selected_start_yearmonth !== null && this.m.selected_end_yearmonth !== null) {
      if (
        util.date2unix(this.m.selected_start_yearmonth + "-01") >
        util.date2unix(this.m.selected_end_yearmonth + "-01")
      ) {
        return true;
      }
    }
    return false;
  }
  get is_year() {
    return (
      this.m.selected_document == 3 ||
      this.m.selected_document == 7 ||
      this.m.selected_document == 9 ||
      this.m.selected_document == 10 ||
      this.m.selected_document == 11 ||
      this.m.selected_document == 13 ||
      this.m.selected_document == 14 ||
      this.m.selected_document == 17 ||
      this.m.selected_document == 23
    );
  }
  get is_branch_monthly() {
    if (this.s_auth == 0) return false;
    return (
      (this.m.selected_document == 0 ||
        this.m.selected_document == 2 ||
        this.m.selected_document == 16 ||
        this.m.selected_document == 24) &&
      this.m.selected_yearmonth !== null
    );
  }
  get is_branch_yearly() {
    if (this.s_auth == 0) return false;
    return (
      ((this.m.selected_document == 3 ||
        this.m.selected_document == 9 ||
        this.m.selected_document == 7 ||
        this.m.selected_document == 10 ||
        this.m.selected_document == 11 ||
        this.m.selected_document == 17 ||
        this.m.selected_document == 26) &&
        this.m.selected_year !== null) ||
      ((this.m.selected_document == 18 ||
        this.m.selected_document == 19 ||
        this.m.selected_document == 20 ||
        this.m.selected_document == 21 ||
        this.m.selected_document == 22) &&
        this.m.selected_egov_type !== null) ||
      ((this.m.selected_document == 13 || this.m.selected_document == 14) &&
        this.m.selected_year !== null) ||
      (this.m.selected_document == 23 &&
        this.m.selected_year !== null &&
        this.m.selected_eltax_type !== null) ||
      ((this.m.selected_document == 6 || this.m.selected_document == 25) &&
        this.m.selected_start_yearmonth !== null &&
        this.m.selected_end_yearmonth !== null) ||
      this.m.selected_document == 5 ||
      this.m.selected_document > 100
    );
  }
  get is_egov() {
    return (
      (this.m.selected_document == 16 && this.m.selected_yearmonth !== null) ||
      this.m.selected_document == 18 ||
      this.m.selected_document == 19 ||
      this.m.selected_document == 20 ||
      this.m.selected_document == 21 ||
      this.m.selected_document == 22
    );
  }
  get is_eltax() {
    return this.m.selected_document == 23 && this.m.selected_year !== null;
  }
  get is_employee_reverse_retire() {
    return (
      this.m.selected_document == 21 ||
      this.m.selected_document == 22 ||
      this.m.selected_document == 23
    );
  }
  get is_employee_no_gray() {
    return this.m.selected_document == 3;
  }
  get is_employee_restax() {
    if (this.s_auth == 0) return false;
    return (
      this.m.selected_document == 23 &&
      this.m.selected_year !== null &&
      this.m.selected_eltax_type !== null
    );
  }
  get is_employee_retired() {
    if (this.s_auth == 0) return false;
    return this.m.selected_document == 5;
  }
  get is_employee_monthly() {
    if (this.s_auth == 0) return false;
    return (
      (this.m.selected_document == 0 ||
        this.m.selected_document == 2 ||
        this.m.selected_document == 24) &&
      this.m.selected_yearmonth !== null
    );
  }
  get is_employee_yearly() {
    if (this.s_auth == 0) return false;
    return (
      ((this.m.selected_document == 3 ||
        this.m.selected_document == 7 ||
        this.m.selected_document == 9 ||
        this.m.selected_document == 10 ||
        this.m.selected_document == 11 ||
        this.m.selected_document == 17 ||
        this.m.selected_document == 26) &&
        this.m.selected_year !== null) ||
      ((this.m.selected_document == 18 ||
        this.m.selected_document == 21 ||
        this.m.selected_document == 22) &&
        this.m.selected_egov_type !== null) ||
      (this.m.selected_document == 13 &&
        this.m.selected_year !== null &&
        this.m.selected_report_type !== null &&
        (this.m.selected_report_type != 2 || this.m.selected_siku_type !== null)) ||
      (this.m.selected_document == 14 &&
        this.m.selected_year !== null &&
        this.m.selected_report_type !== null) ||
      ((this.m.selected_document == 6 || this.m.selected_document == 25) &&
        this.m.selected_start_yearmonth !== null &&
        this.m.selected_end_yearmonth !== null &&
        !this.is_span_error) ||
      (((this.is_custom_document_contract && this.m.selected_custom_type == 0) ||
        (!this.is_custom_document_contract && this.m.selected_custom_type == 2)) &&
        this.m.selected_document > 100)
    );
  }
  get is_employee_emp_ins_get() {
    if (this.s_auth == 0) return false;
    return this.m.selected_document == 19 && this.m.selected_egov_type !== null;
  }
  get is_employee_dependant() {
    if (this.s_auth == 0) return false;
    return this.m.selected_document == 20 && this.m.selected_egov_type !== null;
  }
  get is_employee_bonus_pay() {
    if (this.s_auth == 0) return false;
    return (
      this.m.selected_document == 16 &&
      this.m.selected_yearmonth !== null &&
      this.m.selected_egov_type !== null
    );
  }
  get is_pdf() {
    return (
      ((this.m.selected_document == 0 || this.m.selected_document == 2) &&
        this.m.selected_yearmonth !== null &&
        (this.s_auth == 0 || this.m.selected_employee.length != 0)) ||
      (this.m.selected_document == 1 && this.m.selected_yearmonth !== null) ||
      (this.m.selected_document == 5 &&
        (this.s_auth == 0 || this.m.selected_employee.length != 0)) ||
      ((this.m.selected_document == 3 ||
        this.m.selected_document == 7 ||
        this.m.selected_document == 9 ||
        this.m.selected_document == 11 ||
        this.m.selected_document == 17 ||
        this.m.selected_document == 26) &&
        this.m.selected_year !== null &&
        (this.s_auth == 0 || this.m.selected_employee.length != 0)) ||
      (this.m.selected_document == 10 &&
        this.m.selected_year !== null &&
        this.m.selected_employee.length != 0) ||
      (this.m.selected_document == 16 &&
        this.m.selected_yearmonth !== null &&
        this.m.selected_egov_type == 1 &&
        this.m.selected_employee.length != 0) ||
      ((this.m.selected_document == 18 ||
        this.m.selected_document == 19 ||
        this.m.selected_document == 20 ||
        this.m.selected_document == 21 ||
        this.m.selected_document == 22) &&
        this.m.selected_egov_type == 1 &&
        this.m.selected_employee.length != 0) ||
      (this.m.selected_document == 23 &&
        this.m.selected_year !== null &&
        this.m.selected_eltax_type == 1 &&
        this.m.selected_employee.length != 0) ||
      (this.m.selected_document &&
        this.m.selected_document > 100 &&
        !this.is_custom_document_contract &&
        (this.s_auth == 0 || this.m.selected_employee.length != 0))
    );
  }
  get is_csv() {
    return (
      (this.m.selected_document == 12 && this.m.selected_labor_type !== null) ||
      (this.m.selected_document == 23 &&
        this.m.selected_year !== null &&
        this.m.selected_eltax_type == 0 &&
        this.m.selected_employee.length != 0)
    );
  }
  get is_csv_pdf() {
    return (
      (this.m.selected_document == 6 &&
        this.m.selected_start_yearmonth !== null &&
        this.m.selected_end_yearmonth !== null &&
        !this.is_span_error &&
        (this.s_auth == 0 || this.m.selected_employee.length != 0)) ||
      (this.m.selected_document == 25 &&
        this.m.selected_start_yearmonth !== null &&
        this.m.selected_end_yearmonth !== null &&
        !this.is_span_error &&
        this.m.selected_employee.length != 0)
    );
  }
  get is_paidhol() {
    return (
      this.m.selected_document == 24 &&
      this.m.selected_yearmonth !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_insgetcsv() {
    return (
      ((this.m.selected_document == 18 ||
        this.m.selected_document == 19 ||
        this.m.selected_document == 20 ||
        this.m.selected_document == 21 ||
        this.m.selected_document == 22) &&
        this.m.selected_egov_type == 0 &&
        this.m.selected_employee.length != 0) ||
      (this.m.selected_document == 16 &&
        this.m.selected_yearmonth !== null &&
        this.m.selected_egov_type == 0 &&
        this.m.selected_employee.length != 0)
    );
  }

  get is_send() {
    return (
      this.m.selected_document > 100 &&
      this.is_custom_document_contract &&
      this.m.selected_custom_type == 0 &&
      this.m.selected_employee.length != 0
    );
  }
  get is_labor() {
    return this.m.selected_document == 12;
  }
  get is_report() {
    return (
      (this.m.selected_document == 13 || this.m.selected_document == 14) &&
      this.m.selected_year !== null
    );
  }
  get is_legaltable() {
    return (
      this.m.selected_document == 14 &&
      this.m.selected_year !== null &&
      this.m.selected_report_type == 2 &&
      this.m.selected_table_order !== null
    );
  }
  get is_siku() {
    return (
      this.m.selected_document == 13 &&
      this.m.selected_year !== null &&
      this.m.selected_report_type == 2
    );
  }
  get is_unifiednum() {
    return (
      ((this.m.selected_document == 13 || this.m.selected_document == 14) &&
        this.m.selected_year !== null &&
        this.m.selected_report_type == 0) ||
      (this.m.selected_document == 13 &&
        this.m.selected_year !== null &&
        this.m.selected_report_type == 1)
    );
  }
  get is_paymenttable() {
    return (
      this.m.selected_document == 13 &&
      this.m.selected_year !== null &&
      this.m.selected_siku_type !== null &&
      this.m.selected_report_type == 2 &&
      this.m.selected_employee.length !== 0 &&
      this.m.selected_table_order !== null
    );
  }
  get is_legaltableetax() {
    return (
      this.m.selected_document == 14 &&
      this.m.selected_year !== null &&
      this.m.selected_report_type == 1 &&
      this.m.selected_employee.length !== 0 &&
      this.m.selected_table_order !== null
    );
  }
  get is_paymenttableetax() {
    return (
      this.m.selected_document == 13 &&
      this.m.selected_year !== null &&
      this.m.selected_report_type == 1 &&
      this.m.selected_employee.length !== 0 &&
      this.m.selected_table_order !== null
    );
  }
  get is_bulketax() {
    return (
      (this.m.selected_document == 13 || this.m.selected_document == 14) &&
      this.m.selected_year !== null &&
      this.m.selected_report_type == 0 &&
      this.m.selected_employee.length !== 0 &&
      this.m.selected_table_order !== null
    );
  }
  get is_table_order() {
    return (
      (this.m.selected_document == 13 || this.m.selected_document == 14) &&
      this.m.selected_year !== null &&
      this.m.selected_report_type !== null &&
      this.m.selected_employee.length !== 0
    );
  }

  get is_custom_type() {
    return this.s_auth != 0 && this.m.selected_document > 100;
  }
  get is_history() {
    return (
      this.s_auth != 0 &&
      this.m.selected_document > 100 &&
      this.is_custom_document_contract &&
      this.m.selected_custom_type == 1
    );
  }
  get is_history_employeely() {
    return this.s_auth == 0 && this.m.selected_document > 100 && this.is_custom_document_contract;
  }
  get is_emp_ins_get() {
    return (
      this.m.selected_document == 19 &&
      this.m.selected_egov_type !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_social_ins_get() {
    return (
      this.m.selected_document == 18 &&
      this.m.selected_egov_type !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_social_ins_dependant() {
    return (
      this.m.selected_document == 20 &&
      this.m.selected_egov_type !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_social_ins_lost() {
    return (
      this.m.selected_document == 21 &&
      this.m.selected_egov_type !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_emp_ins_lost() {
    return (
      this.m.selected_document == 22 &&
      this.m.selected_egov_type !== null &&
      this.m.selected_employee.length != 0
    );
  }
  get is_bonus_pay() {
    return (
      this.m.selected_document == 16 &&
      this.m.selected_yearmonth !== null &&
      this.m.selected_egov_type !== null
    );
  }
  get is_transfer() {
    return (
      this.m.selected_document == 23 &&
      this.m.selected_year !== null &&
      this.m.selected_eltax_type !== null &&
      this.m.selected_employee.length != 0
    );
  }

  get is_custom_document_contract() {
    if (this.m.selected_document > 100) {
      return this.document_template_hash[this.m.selected_document - 100].type == 1;
    } else {
      return false;
    }
  }

  get is_multi_employee() {
    return this.m.selected_document != 5;
  }

  get employees() {
    if (this.is_employee_monthly) {
      return this.employee_monthly.employees;
    } else if (this.is_employee_yearly) {
      let employees = [];
      if (this.m.selected_document == 13 && this.m.selected_report_type == 2) {
        employees = this.employee_yearly.employees.filter((employee) =>
          this.siku_yearly.sikus[this.m.selected_siku_type].includes(employee.id),
        );
      } else {
        employees = this.employee_yearly.employees;
      }
      return employees;
    } else if (this.is_employee_dependant) {
      return this.employee_dependant.employees;
    } else if (this.is_employee_bonus_pay) {
      return this.employee_bonus_pay.employees;
    } else if (this.is_employee_retired) {
      return this.employee_retired.employees;
    } else if (this.is_employee_restax) {
      return this.employee_restax.employees;
    } else if (this.is_employee_emp_ins_get) {
      return this.employee_emp_ins_get.employees;
    }
  }

  get contract_documents() {
    if (this.page_name == "document_waiting") {
      if (this.waiting) {
        return this.waiting.contract_documents;
      }
    } else if (this.is_history) {
      return this.history.contract_documents;
    } else if (this.is_history_employeely) {
      return this.history_employeely.contract_documents;
    }
  }

  get employee_hash() {
    if ((this.is_employee_yearly || this.is_history) && this.employee_yearly) {
      return this.employee_yearly.employee_hash;
    } else if (this.is_employee_dependant && this.employee_dependant) {
      return this.employee_dependant.employee_hash;
    } else if (this.is_employee_emp_ins_get && this.employee_emp_ins_get) {
      return this.employee_emp_ins_get.employee_hash;
    } else if (this.is_employee_restax) {
      return this.employee_restax.employee_hash;
    } else if (this.is_employee_bonus_pay) {
      return this.employee_bonus_pay.employee_hash;
    }
  }

  get employee_hash_all() {
    if ((this.is_employee_yearly || this.is_history) && this.employee_yearly) {
      return this.employee_yearly.employee_hash_all;
    }
  }

  get spouce_hash() {
    if (this.is_employee_dependant && this.employee_dependant) {
      return this.employee_dependant.spouces;
    }
  }
  get dependants_hash() {
    if (this.is_employee_dependant && this.employee_dependant) {
      return this.employee_dependant.dependants;
    }
  }

  //pager
  get page() {
    var page = this.query.employee_page;
    if (page === undefined || page == "") {
      this.query = { employee_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { employee_page: undefined };
    } else {
      this.query = { employee_page: val };
    }
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("employee_disp_num", val);
    manager.setDispNum(val);
  }

  get searched_sorted_employees() {
    var employees = util.deep_copy(this.employees);
    employees = this.search_employees(employees);
    employees = this.sort_employees(employees);
    return employees;
  }
  get managed_employees() {
    var employees = util.deep_copy(this.searched_sorted_employees);
    employees = this.page_employees(employees);
    return employees;
  }

  search_employees(employees) {
    if (this.search_key === null) {
      return employees;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return employees.filter((employee) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            employee.name.toLowerCase().indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(employee.kana, key) ||
            (employee.code && employee.code.toLowerCase().indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_employees(employees) {
    if (this.sort_code) employees.sort((x, y) => util.sort_idx(x.code, y.code, this.sort_code));
    else if (this.sort_name)
      employees.sort((x, y) => util.sort_idx(x.kana, y.kana, this.sort_name));
    else if (this.sort_hiring_type)
      employees.sort((x, y) => util.sort_idx(x.hiring_type, y.hiring_type, this.sort_hiring_type));
    else if (this.sort_branch_id)
      employees.sort((x, y) => util.sort_idx(x.branch_id, y.branch_id, this.sort_branch_id));
    else if (this.sort_status)
      employees.sort((x, y) => util.sort_idx(x.status, y.status, this.sort_status));
    else if (this.sort_retired_on)
      employees.sort((x, y) => util.sort_idx(x.retired_on, y.retired_on, this.sort_retired_on));
    else {
      if (this.is_employee_reverse_retire) {
        employees.sort((x, y) => util.sort_idx(x.retired_on, y.retired_on, "desc", true));
      } else {
        employees.sort((x, y) => util.sort_idx(x.retired_on, y.retired_on, "desc", false));
      }
    }
    return employees;
  }
  page_employees(employees) {
    return employees.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }

  get searched_sorted_historys() {
    var historys = util.deep_copy(this.contract_documents);
    historys = this.search_historys(historys);
    historys = this.sort_historys(historys);
    return historys;
  }
  get managed_historys() {
    var historys = util.deep_copy(this.searched_sorted_historys);
    historys = this.page_historys(historys);
    return historys;
  }

  search_historys(historys) {
    if (this.search_key === null) {
      return historys;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      console.log(search_keys);
      var _this = this;
      return historys.filter((history) => {
        const employee = this.employee_hash[history.employee_id];
        if (!employee) return 0;
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            employee.name.toLowerCase().indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(employee.kana, key) ||
            (employee.code && employee.code.toLowerCase().indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_historys(historys) {
    if (this.sort_created_at)
      historys.sort((x, y) => util.sort_idx(x.created_at, y.created_at, this.sort_created_at));
    else if (this.sort_document_template)
      historys.sort((x, y) =>
        util.sort_idx(
          this.document_template_hash[x.document_template_id].name,
          this.document_template_hash[y.document_template_id].name,
          this.sort_document_template,
        ),
      );
    else if (this.sort_code)
      historys.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      historys.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      historys.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      historys.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_status)
      historys.sort((x, y) => util.sort_idx(x.status, y.status, this.sort_status));
    else if (this.sort_contracted_at)
      historys.sort((x, y) =>
        util.sort_idx(x.contracted_at, y.contracted_at, this.sort_contracted_at),
      );
    else historys.sort((x, y) => util.sort_idx(x.created_at, y.created_at, "desc"));
    return historys;
  }
  page_historys(historys) {
    return historys.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }

  get search_key() {
    var search_key = this.query.document_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { document_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { document_search_key: undefined };
    } else {
      this.query = { document_search_key: val };
    }
  }

  sort_click(sort_key) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    console.log(sort_key, sort_mode);
    this.query = {
      document_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      document_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      document_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      document_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,
      document_sort_status: sort_key == "sort_status" ? sort_mode : undefined,
      document_sort_retired_on: sort_key == "sort_retired_on" ? sort_mode : undefined,
      document_sort_created_at: sort_key == "sort_created_at" ? sort_mode : undefined,
      document_sort_contracted_at: sort_key == "sort_contracted_at" ? sort_mode : undefined,
      document_sort_document_template: sort_key == "sort_document_template" ? sort_mode : undefined,
    };
  }

  get sort_created_at() {
    return this.query.document_sort_created_at;
  }
  set sort_created_at(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_created_at: undefined };
    } else {
      this.query = { document_sort_created_at: val };
    }
  }
  get sort_document_template() {
    return this.query.document_sort_document_template;
  }
  set sort_document_template(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_document_template: undefined };
    } else {
      this.query = { document_sort_document_template: val };
    }
  }
  get sort_contracted_at() {
    return this.query.document_sort_contracted_at;
  }
  set sort_contracted_at(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_contracted_at: undefined };
    } else {
      this.query = { document_sort_contracted_at: val };
    }
  }
  get sort_code() {
    return this.query.document_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_code: undefined };
    } else {
      this.query = { document_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.document_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_name: undefined };
    } else {
      this.query = { document_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.document_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_hiring_type: undefined };
    } else {
      this.query = { document_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.document_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_branch_id: undefined };
    } else {
      this.query = { document_sort_branch_id: val };
    }
  }
  get sort_status() {
    return this.query.document_sort_status;
  }
  set sort_status(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_status: undefined };
    } else {
      this.query = { document_sort_status: val };
    }
  }
  get sort_retired_on() {
    return this.query.document_sort_retired_on;
  }
  set sort_retired_on(val) {
    if (val == "" || val == null) {
      this.query = { document_sort_retired_on: undefined };
    } else {
      this.query = { document_sort_retired_on: val };
    }
  }

  get m() {
    return manager;
  }

  get monthly_branch_type() {
    // 店舗の取得で給与ロックからなのか賞与ロックからなのか年末調整からなのか
    // 0: 現在
    // 1: 給与のみ
    // 2: 賞与のみ
    // 3: 給与と賞与
    // 4: 年末調整

    if (this.m.selected_document == 0) {
      return 1;
    } else if (this.m.selected_document == 1) {
      return 3;
    } else if (this.m.selected_document == 2 || this.m.selected_document == 16) {
      return 2;
    } else if (this.m.selected_document == 24) {
      return 4;
    } else {
      return 0;
    }
  }

  get yearly_branch_type() {
    // 店舗の取得で給与ロックからなのか賞与ロックからなのか年末調整からなのか
    // 0: 現在
    // 1: 給与のみ
    // 2: 賞与のみ
    // 3: 給与と賞与
    // 4: 年末調整

    if (this.m.selected_document == 3) {
      return 0;
    } else if (this.m.selected_document == 5) {
      return 0;
    } else if (this.m.selected_document == 6) {
      return 0;
    } else if (this.m.selected_document == 7) {
      return 0;
    } else if (this.m.selected_document == 9) {
      return 0;
    } else if (this.m.selected_document == 10) {
      return 0;
    } else if (this.m.selected_document == 11) {
      return 0;
    } else if (this.m.selected_document == 13 || this.m.selected_document == 14) {
      return 4;
    } else if (this.m.selected_document == 17) {
      return 0;
    } else if (this.m.selected_document == 26) {
      return 0;
    } else {
      return 0;
    }
  }

  //異動届に使用
  get is_show_new_company() {
    var is_show = false;
    Object.keys(this.m.transfer).forEach((employee_id) => {
      if (this.m.transfer[employee_id].after_levy_way == 1) {
        is_show = true;
      }
    });
    return is_show;
  }

  get mode() {
    if (!this.is_manager || this.is_sp) {
      return 0;
    }
    return this.m.mode;
  }
  set mode(val) {
    util.setLocalStorage("document_mode", val);
    manager.setMode(val);
  }
}
