import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import global from "./global";
import manager from "./manager";
import header from "@/vuex/header";
import router from "@/router";
import history from "@/vuex/document/history";
import contract_document from "@/vuex/document/contract_document";
import { validateMediaCode, validateUnifiedNum } from "@/validations/document";
import node from "@/vuex/node";
import { type } from "jquery";

@Module({ dynamic: true, store, name: "document_manager", namespaced: true })
class Document_manager extends VuexModule {
  disp_num: number = 10;

  mode: number = 0; //{0: 従来, 1: シフトB}

  selected_document: number | null = null;
  selected_date: string | null = null;
  selected_yearmonth: string | null = null;
  selected_start_yearmonth: string | null = null;
  selected_end_yearmonth: string | null = null;
  selected_year: number | null = null;
  selected_branch: number | null = null;
  selected_employee: number[] | null = [];
  selected_labor_type: number | null = null;
  selected_report_type: number | null = null;
  selected_custom_type: number | null = null;
  selected_siku_type: string | null = null;
  selected_egov_type: number | null = null;
  selected_eltax_type: number | null = null;
  selected_table_order: number | null = null;
  selected_payment_table_retire_type: boolean = true; //trueが推奨

  unified_num: any = {};

  media_code: number | string | null = null; //賞与支払届に使用
  emp_ins_get: any = {}; //雇用保険資格取得届の追加情報に使用
  emp_ins_lost: any = {}; //雇用保険資格喪失届の追加情報に使用
  social_ins_get: any = {}; //社会保険資格取得届の追加情報に使用
  social_ins_dependant: any = {}; //被扶養者(異動)届 第3号被保険者関係届の追加情報に使用
  social_ins_lost: any = {}; //社会保険資格喪失届の追加情報に使用
  bonus_pay: any = {}; //賞与支払届
  transfer: any = {}; //異動届

  contract: string | null = null; //合意の署名

  @Mutation
  public setDispNum(val) {
    this.disp_num = val;
  }

  @Mutation
  public setContract(val) {
    this.contract = val;
  }

  @Mutation
  public setMediaCode(val: number | string) {
    this.media_code = val;
  }

  @Mutation
  public resetAll() {
    this.selected_document = null;
    this.selected_branch = null;
    this.selected_employee = [];
    this.selected_labor_type = null;
    this.selected_report_type = null;
    this.selected_siku_type = null;
    this.selected_egov_type = null;
    this.selected_eltax_type = null;
    this.selected_custom_type = null;
    this.selected_table_order = null;
    this.selected_payment_table_retire_type = true;
    this.media_code = null;
  }

  @Mutation
  public setSelectedDocument(val?: number) {
    if (this.selected_document == val) {
      this.selected_document = null;
    } else {
      this.selected_document = val;
      const date = new Date();
      if (
        this.selected_document == 3 ||
        this.selected_document == 7 ||
        this.selected_document == 9 ||
        this.selected_document == 10 ||
        this.selected_document == 11 ||
        this.selected_document == 12 ||
        this.selected_document == 13 ||
        this.selected_document == 14 ||
        this.selected_document == 17 ||
        this.selected_document == 23
      ) {
        //year
        if (
          this.selected_document == 3 ||
          this.selected_document == 9 ||
          this.selected_document == 10 ||
          this.selected_document == 13 ||
          this.selected_document == 14 ||
          this.selected_document == 17
        ) {
          if (Number(util.today_obj().month) < 6) {
            this.selected_year = date.getFullYear() - 1;
          } else {
            this.selected_year = date.getFullYear();
          }
        } else if (this.selected_document == 11 || this.selected_document == 12) {
          this.selected_year = date.getFullYear();
        } else if (this.selected_document == 23) {
          if (Number(util.today_obj().month) < 6) {
            this.selected_year = date.getFullYear() - 1;
          }
        } else {
          this.selected_year = date.getFullYear();
        }
      }
    }
    this.selected_branch = null;
    this.selected_employee = [];
    this.selected_labor_type = null;
    this.selected_report_type = null;
    this.selected_siku_type = null;
    this.selected_egov_type = null;
    this.selected_eltax_type = null;
    this.selected_custom_type = null;
    this.selected_table_order = null;
    this.selected_payment_table_retire_type = true;
    this.media_code = null;
    this.emp_ins_get = {};
    this.emp_ins_lost = {};
    this.social_ins_get = {};
    this.social_ins_dependant = {};
    this.social_ins_lost = {};
    this.bonus_pay = {};
    this.transfer = {};
  }
  @Mutation
  public setSelectedDate(val: string) {
    this.selected_date = val;
    this.selected_yearmonth = null;
    this.selected_start_yearmonth = null;
    this.selected_end_yearmonth = null;
    this.selected_year = null;
    this.selected_branch = null;
    this.selected_employee = [];
    this.media_code = null;
  }
  @Mutation
  public setSelectedYearmonth(val: string) {
    this.selected_yearmonth = val;
    this.selected_start_yearmonth = null;
    this.selected_end_yearmonth = null;
    this.selected_date = null;
    this.selected_year = null;
    this.selected_branch = null;
    this.selected_employee = [];
    this.media_code = null;
  }
  @Mutation
  public setSelectedStartYearmonth(val: string) {
    this.selected_yearmonth = null;
    this.selected_start_yearmonth = val;
    this.selected_date = null;
    this.selected_year = null;
    this.selected_branch = null;
    this.selected_employee = [];
    this.media_code = null;
  }
  @Mutation
  public setSelectedEndYearmonth(val: string) {
    this.selected_yearmonth = null;
    this.selected_end_yearmonth = val;
    this.selected_date = null;
    this.selected_year = null;
    this.selected_branch = null;
    this.selected_employee = [];
    this.media_code = null;
  }
  @Mutation
  public setSelectedYear(val: number) {
    this.selected_date = null;
    this.selected_yearmonth = null;
    this.selected_start_yearmonth = null;
    this.selected_end_yearmonth = null;
    this.selected_year = val;
    this.selected_branch = null;
    this.selected_employee = [];
    this.selected_report_type = null;
    this.selected_payment_table_retire_type = true;
    this.selected_siku_type = null;
    this.selected_egov_type = null;
    this.selected_eltax_type = null;
  }
  @Mutation
  public setSelectedBranch(val: number) {
    this.selected_branch = val;
    this.selected_employee = [];
  }
  @Mutation
  public setSelectedEmployeeSingle(val: number) {
    this.selected_employee = [val];
  }
  @Mutation
  public resetSelectedEmployee() {
    this.selected_employee = [];
  }
  @Mutation
  public setSelectedEmployees(val: number[]) {
    this.selected_employee = val;
  }
  @Mutation
  public setSelectedEmployeeMulti(val: number) {
    const index = this.selected_employee.indexOf(val);
    if (index === -1) {
      this.selected_employee.push(val);
    } else {
      this.selected_employee.splice(index, 1);
    }
  }
  @Mutation
  public setSelectedLaborType(val: number) {
    if (this.selected_labor_type == val) {
      this.selected_labor_type = null;
    } else {
      this.selected_labor_type = val;
    }
  }
  @Mutation
  public setSelectedPaymentTableRetireType(val: boolean) {
    this.selected_payment_table_retire_type = val;
  }
  @Mutation
  public setSelectedReportType(val: number) {
    if (this.selected_report_type == val) {
      this.selected_report_type = null;
    } else {
      this.selected_report_type = val;
    }
  }
  @Mutation
  public setSelectedTableOrder(val: number) {
    if (this.selected_table_order == val) {
      this.selected_table_order = null;
    } else {
      this.selected_table_order = val;
    }
  }
  @Mutation
  public setSelectedCustomType(val: number) {
    this.selected_employee = [];
    if (this.selected_custom_type == val) {
      this.selected_custom_type = null;
    } else {
      this.selected_custom_type = val;
    }
  }
  @Mutation
  public setSelectedSikuType(val: string) {
    this.selected_employee = [];
    if (this.selected_siku_type == val) {
      this.selected_siku_type = null;
    } else {
      this.selected_siku_type = val;
    }
  }
  @Mutation
  public setSelectedEgovType(val: number) {
    this.selected_employee = [];
    this.emp_ins_get = {};
    this.emp_ins_lost = {};
    this.social_ins_get = {};
    this.social_ins_lost = {};
    this.social_ins_dependant = {};
    this.transfer = {};
    if (this.selected_egov_type == val) {
      this.selected_egov_type = null;
    } else {
      this.selected_egov_type = val;
    }
  }
  @Mutation
  public setSelectedEltaxType(val: number) {
    this.selected_employee = [];
    this.emp_ins_get = {};
    this.emp_ins_lost = {};
    this.social_ins_get = {};
    this.social_ins_lost = {};
    this.social_ins_dependant = {};
    this.transfer = {};
    if (this.selected_eltax_type == val) {
      this.selected_eltax_type = null;
    } else {
      this.selected_eltax_type = val;
    }
  }
  @Mutation
  public setGetEmpIns({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.emp_ins_get, employee_id, data);
  }
  @Mutation
  public unsetGetEmpIns({ employee_id }: { employee_id: number }) {
    Vue.delete(this.emp_ins_get, employee_id);
  }
  @Mutation
  public setLostEmpIns({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.emp_ins_lost, employee_id, data);
  }
  @Mutation
  public unsetLostEmpIns({ employee_id }: { employee_id: number }) {
    Vue.delete(this.emp_ins_lost, employee_id);
  }
  @Mutation
  public setGetSocialIns({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.social_ins_get, employee_id, data);
  }
  @Mutation
  public unsetGetSocialIns({ employee_id }: { employee_id: number }) {
    Vue.delete(this.social_ins_get, employee_id);
  }
  @Mutation
  public setTransfer({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.transfer, employee_id, data);
  }
  @Mutation
  public unsetTransfer({ employee_id }: { employee_id: number }) {
    Vue.delete(this.transfer, employee_id);
  }
  @Mutation
  public setBonusPay({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.bonus_pay, employee_id, data);
  }
  @Mutation
  public unsetBonusPay({ employee_id }: { employee_id: number }) {
    Vue.delete(this.bonus_pay, employee_id);
  }
  @Mutation
  public setLostSocialIns({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.social_ins_lost, employee_id, data);
  }
  @Mutation
  public unsetLostSocialIns({ employee_id }: { employee_id: number }) {
    Vue.delete(this.social_ins_lost, employee_id);
  }
  @Mutation
  public setDependantSocialIns({ employee_id, data }: { employee_id: number; data: any }) {
    Vue.set(this.social_ins_dependant, employee_id, data);
  }
  @Mutation
  public unsetDependantSocialIns({ employee_id }: { employee_id: number }) {
    Vue.delete(this.social_ins_dependant, employee_id);
  }

  @Mutation
  public resetUnifiedNum() {
    this.unified_num = {};
  }
  @Mutation
  public setUnifiedNumValue({ name, val }) {
    if (!this.unified_num[name]) {
      Vue.set(this.unified_num, name, { value: null, invalid: true });
    }
    Vue.set(this.unified_num[name], "value", val);
  }
  @Mutation
  public setUnifiedNumInvalid({ name, val }) {
    if (!this.unified_num[name]) {
      Vue.set(this.unified_num, name, { value: null, invalid: true });
    }
    Vue.set(this.unified_num[name], "invalid", val);
    if (val) {
      Vue.set(this.unified_num[name], "value", null);
    }
  }

  @Action({ rawError: true })
  public cancelAll() {}

  @Action({ rawError: true })
  public async download(args) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    if (this.selected_document == 0) {
      await util.post_document_split_proxy(
        "document/generate_paymentslip",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "支給分給与明細書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 1) {
      await util.post_document_proxy(
        "document/generate_gensenpay",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "分源泉税徴収高計算書.pdf",
      );
    } else if (this.selected_document == 2) {
      await util.post_document_split_proxy(
        "document/generate_bonusslip",
        { yearmonth: this.selected_yearmonth },
        util.yearmonth2str(this.selected_yearmonth) + "支給分賞与明細書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 3) {
      await util.post_document_split_proxy(
        "document/generate_gensentax_user",
        { year: this.selected_year },
        this.selected_year + "年源泉徴収票(受給者用)",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 5) {
      await util.post_document_proxy(
        "document/generate_retire_table",
        { employee_id: this.selected_employee[0] },
        "雇用保険被保険者離職票.pdf",
      );
    } else if (this.selected_document == 6) {
      if (args.type == 0) {
        await util.post_document_proxy(
          "document/generate_wagerecord",
          {
            start_yearmonth: this.selected_start_yearmonth,
            end_yearmonth: this.selected_end_yearmonth,
            employee_ids: this.selected_employee,
          },
          "賃金台帳.csv",
        );
      } else if (args.type == 1) {
        await util.post_document_proxy(
          "document/generate_wagerecord_pdf",
          {
            start_yearmonth: this.selected_start_yearmonth,
            end_yearmonth: this.selected_end_yearmonth,
            employee_ids: this.selected_employee,
          },
          "賃金台帳.zip",
        );
      }
    } else if (this.selected_document == 7) {
      await util.post_document_split_proxy(
        "document/generate_dependant_deduction",
        { year: this.selected_year },
        this.selected_year + "年扶養控除等申告書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 9) {
      await util.post_document_split_proxy(
        "document/generate_insurance_deduction",
        { year: this.selected_year },
        this.selected_year + "年保険料控除申告書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 10) {
      await util.post_document_split_proxy(
        "document/generate_housing_loan",
        { year: this.selected_year },
        this.selected_year + "年住宅借入金等特別控除申告書",
        "zip",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 11) {
      await util.post_document_split_proxy(
        "document/generate_gensenbook",
        { year: this.selected_year },
        this.selected_year + "年源泉徴収簿",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 12) {
      if (this.selected_labor_type == 0) {
        //本人情報
        await util.post_document_proxy(
          "document/generate_self_csv",
          {},
          "労働者名簿(本人情報).csv",
        );
      } else if (this.selected_labor_type == 1) {
        //労務情報
        await util.post_document_proxy(
          "document/generate_company_csv",
          {},
          "労働者名簿(労務情報).csv",
        );
      } else if (this.selected_labor_type == 2) {
        //時給一覧
        await util.post_document_proxy(
          "document/generate_hourly_wage_csv",
          {},
          "労働者名簿(時給一覧).csv",
        );
      } else if (this.selected_labor_type == 3) {
        //研修時給一覧
        await util.post_document_proxy(
          "document/generate_training_hourly_wage_csv",
          {},
          "労働者名簿(研修時給一覧).csv",
        );
      }
    } else if (this.selected_document == 13) {
      this.download_paymenttable({ args });
    } else if (this.selected_document == 14) {
      this.download_legaltable({ args });
    } else if (this.selected_document == 16) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_social_ins_summery",
              {
                employee_ids: this.selected_employee,
                yearmonth: this.selected_yearmonth,
                bonus_pay: this.bonus_pay,
                media_code: this.media_code,
              },
              "社会保険被保険者資格取得届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_bonus_pay_csv",
              {
                employee_ids: this.selected_employee,
                yearmonth: this.selected_yearmonth,
                bonus_pay: this.bonus_pay,
                media_code: this.media_code,
              },
              "SHFD0006.CSV",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_bonus_pay_pdf",
          {
            employee_ids: this.selected_employee,
            yearmonth: this.selected_yearmonth,
            bonus_pay: this.bonus_pay,
          },
          "賞与支払届.pdf",
        );
      }
    } else if (this.selected_document == 17) {
      await util.post_document_split_proxy(
        "document/generate_kihaisho",
        { year: this.selected_year },
        this.selected_year + "年基・配・所控除申告書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document == 18) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_social_ins_summery",
              {
                employee_ids: this.selected_employee,
                social_ins_get: this.social_ins_get,
                media_code: this.media_code,
              },
              "社会保険被保険者資格取得届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_social_ins_get_csv",
              {
                employee_ids: this.selected_employee,
                social_ins_get: this.social_ins_get,
                media_code: this.media_code,
              },
              "SHFD0006.csv",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_social_ins_get_pdf",
          { employee_ids: this.selected_employee, social_ins_get: this.social_ins_get },
          "社会保険被保険者資格取得届.pdf",
        );
      }
    } else if (this.selected_document == 19) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_emp_ins_summery",
              {
                employee_ids: this.selected_employee,
                emp_ins_get: this.emp_ins_get,
                media_code: this.media_code,
              },
              "雇用保険保険被保険者資格取得届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_emp_ins_get_csv",
              {
                employee_ids: this.selected_employee,
                emp_ins_get: this.emp_ins_get,
                media_code: this.media_code,
              },
              "10101-shutoku.csv",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_emp_ins_get_pdf",
          { employee_ids: this.selected_employee, emp_ins_get: this.emp_ins_get },
          "雇用保険被保険者資格取得届.pdf",
        );
      }
    } else if (this.selected_document == 20) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_social_ins_summery",
              {
                employee_ids: this.selected_employee,
                social_ins_dependant: this.social_ins_dependant,
                media_code: this.media_code,
              },
              "被扶養者(異動)届/第3号被保険者関係届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_social_ins_dependant_csv",
              {
                employee_ids: this.selected_employee,
                social_ins_dependant: this.social_ins_dependant,
                media_code: this.media_code,
              },
              "SHFD0006.csv",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_social_ins_dependant_pdf",
          { employee_ids: this.selected_employee, social_ins_dependant: this.social_ins_dependant },
          "被扶養者(異動)届/第3号被保険者関係届.pdf",
        );
      }
    } else if (this.selected_document == 21) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_social_ins_summery",
              {
                employee_ids: this.selected_employee,
                social_ins_lost: this.social_ins_lost,
                media_code: this.media_code,
              },
              "社会保険被保険者資格喪失届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_social_ins_lost_csv",
              {
                employee_ids: this.selected_employee,
                social_ins_lost: this.social_ins_lost,
                media_code: this.media_code,
              },
              "SHFD0006.csv",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_social_ins_lost_pdf",
          { employee_ids: this.selected_employee, social_ins_lost: this.social_ins_lost },
          "社会保険被保険者資格喪失届.pdf",
        );
      }
    } else if (this.selected_document == 22) {
      if (this.selected_egov_type == 0) {
        //CSV
        if (validate(validateMediaCode(this.media_code))) {
          if (args.type === 0) {
            await util.post_document_proxy(
              "document/generate_emp_ins_summery",
              {
                employee_ids: this.selected_employee,
                emp_ins_lost: this.emp_ins_lost,
                media_code: this.media_code,
              },
              "雇用保険保険被保険者資格喪失届電子申請総括票.pdf",
            );
          } else if (args.type == 1) {
            await util.post_document_proxy(
              "document/generate_emp_ins_lost_csv",
              {
                employee_ids: this.selected_employee,
                emp_ins_lost: this.emp_ins_lost,
                media_code: this.media_code,
              },
              "10191-soshitsu.csv",
            );
          }
        }
      } else if (this.selected_egov_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_emp_ins_lost_pdf",
          { employee_ids: this.selected_employee, emp_ins_lost: this.emp_ins_lost },
          "雇用保険被保険者資格喪失届.pdf",
        );
      }
    } else if (this.selected_document == 23) {
      if (this.selected_eltax_type == 0) {
        //CSV
        await util.post_document_proxy(
          "document/generate_transfer_csv",
          { employee_ids: this.selected_employee, transfer: this.transfer },
          "arrow-transfer.csv",
        );
      } else if (this.selected_eltax_type == 1) {
        //書類
        await util.post_document_proxy(
          "document/generate_transfer_pdf",
          { employee_ids: this.selected_employee, transfer: this.transfer },
          "給与所得者異動届出書.pdf",
        );
      }
    } else if (this.selected_document == 24) {
      if (args.type == 0) {
        await util.post_document_proxy(
          "document/generate_paidhol_csv",
          { employee_ids: this.selected_employee, yearmonth: this.selected_yearmonth },
          util.yearmonth2str(this.selected_yearmonth) + "有給管理表.csv",
        );
      } else if (args.type == 1) {
        await util.post_document_proxy(
          "document/generate_remain_paidhol_csv",
          { employee_ids: this.selected_employee, yearmonth: this.selected_yearmonth },
          util.yearmonth2str(this.selected_yearmonth) + "有給残日数表.csv",
        );
      }
    } else if (this.selected_document == 25) {
      if (args.type == 0) {
        await util.post_document_proxy(
          "document/generate_attendance_csvs",
          {
            employee_ids: this.selected_employee,
            start_yearmonth: this.selected_start_yearmonth,
            end_yearmonth: this.selected_end_yearmonth,
          },
          util.formatDate(util.date2obj(this.selected_start_yearmonth + "-01"), "yyyy年mm月") +
            "〜" +
            util.formatDate(util.date2obj(this.selected_end_yearmonth + "-01"), "yyyy年mm月") +
            "支給分勤怠詳細データCSV.zip",
        );
      } else if (args.type == 1) {
        await util.post_document_proxy(
          "document/generate_attendance_pdfs",
          {
            employee_ids: this.selected_employee,
            start_yearmonth: this.selected_start_yearmonth,
            end_yearmonth: this.selected_end_yearmonth,
          },
          util.formatDate(util.date2obj(this.selected_start_yearmonth + "-01"), "yyyy年mm月") +
            "〜" +
            util.formatDate(util.date2obj(this.selected_end_yearmonth + "-01"), "yyyy年mm月") +
            "支給分勤怠詳細データPDF.zip",
        );
      }
    } else if (this.selected_document == 26) {
      await util.post_document_split_proxy(
        "document/generate_fixed_tax_reduction",
        { year: this.selected_year },
        "定額減税申告書",
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    } else if (this.selected_document > 100) {
      await util.post_document_split_proxy(
        "document/generate_custom_document",
        { document_template_id: args.document_template_id },
        args.document_template_name,
        "pdf",
        "employee_ids",
        this.selected_employee,
        20,
      );
    }
  }

  @Action({ rawError: true })
  public async download_paymenttable({ args }) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    if (this.selected_report_type == 0) {
      if (args.type == 0) {
        //CSV
        if (validate(validateUnifiedNum(args.sikus, this.unified_num))) {
          dialog.openConfirmDialog({
            msg: "郵便番号が正しくない社員が存在する場合は正しく出力されません。<br>CSVを出力しますか？",
            cancel_button: "出力しない",
            exec_button: "出力する",
            func: this.generateUnifiedCsv,
            args: {},
            is_next_dialog: true,
          });
        }
      } else if (args.type == 1) {
        //総括表
        await util.post_document_proxy(
          "document/generate_legal_table",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
            type: 1,
          },
          this.selected_year + "年法定調書合計表.pdf",
        );
      }
    } else if (this.selected_report_type == 1) {
      if (args.type == 0) {
        //CSV
        if (validate(validateUnifiedNum(args.sikus, this.unified_num))) {
          dialog.openConfirmDialog({
            msg: "郵便番号が正しくない社員が存在する場合は正しく出力されません。<br>CSVを出力しますか？",
            cancel_button: "出力しない",
            exec_button: "出力する",
            func: this.generatePaymentTableCsv,
            args: {},
            is_next_dialog: true,
          });
        }
      }
    } else if (this.selected_report_type == 2) {
      //書類で提出
      if (args.type == 1) {
        //総括表
        await util.post_document_proxy(
          "document/generate_payment_table",
          {
            year: this.selected_year,
            is_retire: this.selected_payment_table_retire_type,
            table_order: this.selected_table_order,
            employee_ids: this.selected_employee,
          },
          this.selected_year + "年総括表(給与支払報告書).pdf",
        );
      } else if (args.type == 0) {
        await util.post_document_split_proxy(
          "document/generate_payment_table_gensentax",
          {
            year: this.selected_year,
            table_order: this.selected_table_order,
            is_retire: this.selected_payment_table_retire_type,
          },
          this.selected_year + "年個人別明細書(給与支払報告書)",
          "pdf",
          "employee_ids",
          this.selected_employee,
          20,
        );
      }
    } else {
      dialog.openErrorDialog({ error: ["メンテナンス中です。もうしばらくお待ちください。"] });
    }
  }

  @Action({ rawError: true })
  public async download_legaltable({ args }) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    if (this.selected_report_type == 0) {
      if (args.type == 0) {
        //CSV
        if (validate(validateUnifiedNum(args.sikus, this.unified_num))) {
          dialog.openConfirmDialog({
            msg: "郵便番号が正しくない社員が存在する場合は正しく出力されません。<br>CSVを出力しますか？",
            cancel_button: "出力しない",
            exec_button: "出力する",
            func: this.generateUnifiedCsv,
            args: {},
            is_next_dialog: true,
          });
        }
      } else if (args.type == 1) {
        //総括表
        await util.post_document_proxy(
          "document/generate_legal_table",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
            type: 1,
          },
          this.selected_year + "年法定調書合計表.pdf",
        );
      }
    } else if (this.selected_report_type == 1) {
      if (args.type == 0) {
        //CSV
        await util.post_document_proxy(
          "document/generate_etax_csv",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
          },
          this.selected_year + "_etax.csv",
          "提出すべき人がいません",
        );
      } else if (args.type == 1) {
        //総括表
        await util.post_document_proxy(
          "document/generate_legal_table",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
            type: 1,
          },
          this.selected_year + "年法定調書合計表.pdf",
        );
      }
    } else if (this.selected_report_type == 2) {
      //書類で提出
      if (args.type == 1) {
        //法定調書合計表
        await util.post_document_proxy(
          "document/generate_legal_table",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
            type: 0,
          },
          this.selected_year + "年法定調書合計表.pdf",
        );
      } else if (args.type == 0) {
        //源泉徴収票
        await util.post_document_proxy(
          "document/generate_legal_table_gensentax",
          {
            year: this.selected_year,
            employee_ids: this.selected_employee,
            table_order: this.selected_table_order,
          },
          this.selected_year + "年源泉徴収票(法定調書合計表).pdf",
          "提出すべき人がいません",
        );
      }
    } else {
      dialog.openErrorDialog({ error: ["メンテナンス中です。もうしばらくお待ちください。"] });
    }
  }

  @Action({ rawError: true })
  public async deleteContractDocument({ contract_document_id }: { contract_document_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "この書類を削除しますか？",
      detail: "削除した書類は元に戻すことはできません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      is_next_dialog: true,
      func: this.deleteContractDocumentConfirm,
      args: { contract_document_id },
    });
  }
  @Action({ rawError: true })
  public deleteContractDocumentConfirm({ contract_document_id }: { contract_document_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail: "この書類の情報は削除され、元に戻すことはできません。<br>本当によろしいですか？",
      cancel_button: "更新しない",
      exec_button: "更新する",
      func: this.deleteContractDocumentProcess,
      args: { contract_document_id },
    });
  }
  @Action({ rawError: true })
  public async deleteContractDocumentProcess({
    contract_document_id,
  }: {
    contract_document_id: number;
  }) {
    await util.post("document/delete_contract_document", { contract_document_id });
    node.disable();
  }

  @Action({ rawError: true })
  public async send() {
    dialog.openProgressDialog({
      title: "送信中",
      msg: "送信に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    var res = await util.post("document/send_contract_document", {
      document_template_id: this.selected_document - 100,
      employee_ids: this.selected_employee,
    });
    if (res) {
      dialog.openAlertDialog({ msg: "送信しました" });
      this.setSelectedDocument(null);
    }
  }

  @Action({ rawError: true })
  public async generateUnifiedCsv({}) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post_document_proxy(
      "document/generate_unified_csv",
      {
        year: this.selected_year,
        is_retire: this.selected_payment_table_retire_type,
        table_order: this.selected_table_order,
        unified_num: this.unified_num,
        employee_ids: this.selected_employee,
        is_unified: true,
      },
      this.selected_year + "_unified.csv",
    );
  }

  @Action({ rawError: true })
  public async generatePaymentTableCsv({}) {
    dialog.openProgressDialog({
      title: "作成中",
      msg: "作成に時間がかかることがあります。",
      detail: "そのままお待ちください。",
    });
    await util.post_document_proxy(
      "document/generate_unified_csv",
      {
        year: this.selected_year,
        employee_ids: this.selected_employee,
        table_order: this.selected_table_order,
        is_retire: this.selected_payment_table_retire_type,
        unified_num: this.unified_num,
        is_unified: false,
      },
      this.selected_year + "_paymenttable.csv",
    );
  }

  @Action({ rawError: true })
  public async admitContractDocument({ contract_document_id }: { contract_document_id: number }) {
    if (this.contract !== null) {
      dialog.openForceConfirmDialog({
        msg: util.$t("contract_document_admit_confirm"),
        detail: util.$t("contract_document_admit_confirm_detail"),
        cancel_button: util.$t("contract_document_no_admit"),
        exec_button: util.$t("contract_document_admit"),
        is_next_dialog: true,
        func: this.admitContractDocumentProcess,
        args: { contract_document_id },
      });
    } else {
      dialog.openErrorDialog({ error: [util.$t("error.contract_document_enter_auth")] });
    }
  }
  @Action({ rawError: true })
  public async admitContractDocumentProcess({
    contract_document_id,
  }: {
    contract_document_id: number;
  }) {
    var res = await util.post("document/admit_contract_document", {
      contract_document_id,
      contract: this.contract,
    });
    if (res) {
      node.disable();
      router.push({ name: "document_waiting" });
      dialog.openAlertDialog({ msg: util.$t("contract_document_admit_finish") });
    }
  }

  @Action({ rawError: true })
  public async denyContractDocument({ contract_document_id }: { contract_document_id: number }) {
    if (this.contract !== null) {
      dialog.openForceConfirmDialog({
        msg: util.$t("contract_document_deny_confirm"),
        detail: util.$t("contract_document_deny_confirm_detail"),
        cancel_button: util.$t("contract_document_no_deny"),
        exec_button: util.$t("contract_document_deny"),
        is_next_dialog: true,
        func: this.denyContractDocumentProcess,
        args: { contract_document_id },
      });
    } else {
      dialog.openErrorDialog({ error: [util.$t("error.contract_document_enter_auth")] });
    }
  }
  @Action({ rawError: true })
  public async denyContractDocumentProcess({
    contract_document_id,
  }: {
    contract_document_id: number;
  }) {
    var res = await util.post("document/deny_contract_document", {
      contract_document_id,
      contract: this.contract,
    });
    if (res) {
      node.disable();
      router.push({ name: "document_waiting" });
      dialog.openAlertDialog({ msg: util.$t("contract_document_deny_finish") });
    }
  }

  @Mutation
  setMode(mode: number) {
    this.mode = mode;
  }
}

export default getModule(Document_manager);
