import Able_pattern from "@/typesold/able_pattern";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validateMediaCode(media_code: any) {
  var error = [];

  error.push(media_code != null || "媒体通番を入力してください");
  error.push(
    media_code == null || vali.is_number(media_code) || "媒体通番は半角数字で入力してください",
  );
  error.push(
    media_code == null ||
      !vali.is_number(media_code) ||
      media_code.length == 3 ||
      "媒体通番は3桁で入力してください",
  );

  return error;
}

export function validateUnifiedNum(sikus: any, unified_num: any) {
  var error = [];

  Object.keys(sikus).forEach((name) => {
    if (name != "不明") {
      error.push(
        unified_num[name] !== undefined ||
          name + "の指定番号の入力フォームエラーです<br>ブラウザをリロードしてください",
      );
      if (unified_num[name]) {
        error.push(
          unified_num[name].invalid ||
            unified_num[name].value !== null ||
            name + "の指定番号が入力されていません",
        );
      }
    }
  });

  return error;
}

export function validateTransfer(sikus: any, unified_num: any) {
  var error = [];

  Object.keys(sikus).forEach((name) => {
    if (name != "不明") {
      error.push(
        unified_num[name] !== undefined ||
          name + "の指定番号の入力フォームエラーです<br>ブラウザをリロードしてください",
      );
      if (unified_num[name]) {
        error.push(
          unified_num[name].invalid ||
            unified_num[name].value !== null ||
            name + "の指定番号が入力されていません",
        );
      }
    }
  });

  return error;
}
